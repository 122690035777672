import React from "react";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { SelectedImageCheck } from "../images/checkIcon";
import { videoIcon } from "components/common/File/icons";

const useStyles = makeStyles((theme) => ({
  image: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  imageItem: {
    width: "100%",
    paddingTop: "100%",
    position: "relative",
    borderRadius: 6,
    overflow: "hidden",
    transition: "300ms",
  },
  imageItemSelected: {
    filter: "brightness(0.8)",
  },
  selectedIcon: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  selectedImage: {
    cursor: "pointer",
    "&:hover": {
      filter: "brightness(0.8)",
    },
  },
}));

const ImageGalleryGridItem = ({
  url,
  index,
  isSelected,
  onClick,
  onOpenImage,
  viewOnly,
  id,
  mime_type,
}) => {
  const classes = useStyles();

  return (
    <Box
      className={[
        classes.imageItem,
        viewOnly ? "" : classes.selectedImage,
      ].join(" ")}
      onClick={() => {
        if (viewOnly) {
          onOpenImage({ index });
        } else {
          onClick(index);
        }
      }}
    >
      {mime_type === "3" || mime_type === "5" ? (
        <Box
          style={{
            display: "flex",
            height: "100%",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            background: "#747474",
          }}
          className={`${classes.image} ${
            isSelected ? classes.imageItemSelected : ""
          }`}
        >
          {videoIcon(90, 90)}
        </Box>
      ) : (
        <img
          src={url}
          alt={`Grid item #${index}`}
          className={`${classes.image} ${
            isSelected ? classes.imageItemSelected : ""
          }`}
        />
      )}
      {isSelected && !viewOnly && (
        <Box className={classes.selectedIcon}>
          <SelectedImageCheck />
        </Box>
      )}
    </Box>
  );
};

export default ImageGalleryGridItem;
