import React, { useContext, useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Skeleton from "@material-ui/lab/Skeleton";
import AnalysisCard from "components/common/LunaLens/analysisCard";
import FormInput from "components/ui/FormContent/formInput";
import SendIcon from "@material-ui/icons/Send";
import IconButton from "@material-ui/core/IconButton";
import useStyles from "./styles";
import useLunaLensStore from "store/lunaLensAnalysis";
import AnalysisInformationCard from "./analysisInformationCard";
import AnalysisCategoriesAccordionTab from "./analysisCategoriesAccordionTab";
import AnalysisPromptCard from "./analysisPromptCard";
import AnalysisLensProgress from "./analysisLensProgress";
import {
  addAnalysis,
  getAnalysisById,
  getAnalysisThread,
  getLunaLensCategories,
  processAnalysis,
  saveAnalysis,
  saveFeedback,
  updateAnalysis,
} from "services/lunaLensService";
import ZeroState from "./zeroState";
import LensComments from "./lensComments";
import GlobalUiContext from "contexts/globalUiContext";
import { permissionSurface, hasPermission } from "lib/permissions";
import LensCommentsSurface from "./lensCommentsSurface";
import DialogLoadingLuna from "./dialog/dialogLoadingLuna";
import DialogFeedbackLuna from "./dialog/dialogFeedbackLuna";

const transformAnalysisResponse = (categories) => {
  if (!categories) return {};
  const transformed = {};

  categories.forEach((category) => {
    const categoryValue = category.luna_lens_category.value;
    const trades = category.luna_lens_analysis_categories_trade.map(
      (trade) => ({
        title: trade.title,
        description: trade.description,
        image_urls: trade.luna_lens_trade_media.map(
          (media) => media?.file.file_url
        ),
      })
    );

    transformed[categoryValue] = trades;
  });

  return transformed;
};

const transformData = (data) => {
  if (!data) return {};
  const result = {
    quality_issues: [],
    general_observations: [],
    health_and_safety_risks: [],
    services_recommendations: [],
    asset_tagging: [],
    sla_compliance: [],
    service_verification_before: [],
    service_verification_after: [],
  };

  data.forEach((item) => {
    const category = item.luna_lens_category.value;
    item.luna_lens_category_media.forEach((media) => {
      const mediaItem = {
        id: media.file.id,
        url: media.file.file_url,
        mime_type: media.file.mime_type,
      };

      if (category === "service_verification") {
        if (media.verification === "before") {
          result.service_verification_before.push(mediaItem);
        } else if (media.verification === "after") {
          result.service_verification_after.push(mediaItem);
        }
      } else if (result[category]) {
        result[category].push(mediaItem);
      }
    });
  });

  return result;
};

const NewAnalysisTab = ({ id }) => {
  const classes = useStyles();

  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;

  const [analyzing, setAnalyzing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [value2, setValue2] = useState("");
  const [categoryContext, setCategoryContext] = useState([]);
  const [savedAnalysis, setSavedAnalysis] = useState();
  const [openFeedbackDialog, setOpenFeedbackDialog] = useState(false);
  const [indexCategoryThumbDown, setIndexCategoryThumbDown] = useState();
  const [loadingAnalysis, setLoadingAnalysis] = useState(false);
  const [currentWorkticketAnalysis, setCurrentWorkticketAnalysis] = useState();
  const [loadingUpdateAnalysis, setLoadingUpdateAnalysis] = useState(false);
  const [loadingAddAnalysis, setLoadingAddAnalysis] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);

  const selectedImages = useLunaLensStore((state) => state.selectedImages);
  const setAnalysisThread = useLunaLensStore(
    (state) => state.setAnalysisThread
  );
  const analysisThread = useLunaLensStore((state) => state.analysisThread);
  const categoryList = useLunaLensStore((state) => state.categoryList);
  const setCategoryList = useLunaLensStore((state) => state.setCategoryList);
  const setNewSelectedImages = useLunaLensStore(
    (state) => state.setNewSelectedImages
  );
  const setSavedLensAnalysis = useLunaLensStore(
    (state) => state.setSavedLensAnalysis
  );
  const updateAnalysisTrade = useLunaLensStore(
    (state) => state.updateAnalysisTrade
  );
  const setAnalysisFailed = useLunaLensStore(
    (state) => state.setAnalysisFailed
  );
  const setAnalysisResponse = useLunaLensStore(
    (state) => state.setAnalysisResponse
  );

  const setAnalysisStarted = useLunaLensStore(
    (state) => state.setAnalysisStarted
  );

  const setWorkticketAnalysisFeedback = useLunaLensStore(
    (state) => state.setWorkticketAnalysisFeedback
  );

  useEffect(() => {
    const loadLunaLensData = async () => {
      setLoadingAnalysis(true);
      const user = JSON.parse(localStorage.getItem("user"));
      const res = await getLunaLensCategories(user.id);
      setCategoryList(res.data.categories);

      const response = await getAnalysisThread({ id: parseInt(id) });
      const analysis = response.data.analysis;
      setAnalysisThread(analysis);
      const imagesTransformed = transformData(
        analysis?.luna_lens_analysis_media
      );
      setNewSelectedImages(imagesTransformed);
      const savedId = analysis?.luna_lens_workticket_analysis.find(
        (thread) => thread.saved
      ).id;
      const currentId = analysis?.luna_lens_workticket_analysis.slice(-1)[0].id;
      setSavedAnalysis(savedId);
      setCurrentWorkticketAnalysis(currentId);
      if (analysis?.status === 0) {
        setLoading(true);
      }
      setLoadingAnalysis(false);
    };
    loadLunaLensData();
  }, [setAnalysisThread, setCategoryList, id, setNewSelectedImages]);

  useEffect(() => {
    const checkAnalysis = async () => {
      if (analysisThread?.id) {
        const response = await getAnalysisById({ id: analysisThread.id });
        const lunaResponse = response.data.analysis;
        if (lunaResponse?.status === 1) {
          if (
            lunaResponse?.luna_lens_workticket_analysis[0]
              ?.luna_lens_analysis_categories?.length > 0
          ) {
            setAnalysisResponse(
              lunaResponse.luna_lens_workticket_analysis[0]?.id,
              lunaResponse.luna_lens_workticket_analysis[0]
                ?.luna_lens_analysis_categories,
              lunaResponse.response_text,
              lunaResponse.luna_lens_workticket_analysis[0]?.json_response
            );
            setCurrentWorkticketAnalysis(
              lunaResponse.luna_lens_workticket_analysis[0]?.id
            );
            setLoading(false);
            setAnalyzing(false);
            processAnalysis({
              id: lunaResponse.luna_lens_workticket_analysis[0]?.id,
            });
          }
        } else if (lunaResponse?.status === 2) {
          if (lunaResponse.luna_lens_workticket_analysis.length > 0) {
            setAnalysisFailed(
              lunaResponse.luna_lens_workticket_analysis[0]?.id
            );
            setLoading(false);
            setAnalyzing(false);
            processAnalysis({
              id: lunaResponse.luna_lens_workticket_analysis[0]?.id,
            });
          }
        }
      }
    };
    checkAnalysis();
    const intervalId = setInterval(checkAnalysis, 30000);

    return () => clearInterval(intervalId);
  }, [analysisThread, setAnalysisResponse, setAnalysisFailed]);

  const addCategoryContext = (category) => {
    setCategoryContext((prevContext) => {
      const categoryExists = prevContext.some((cat) => cat.value === category);
      if (categoryExists) {
        return prevContext.filter((cat) => cat.value !== category);
      } else {
        const newCategory = categoryList.find((cat) => cat.value === category);
        return [...prevContext, newCategory];
      }
    });
  };

  const handleCloseFeedbackDialog = () => {
    setOpenFeedbackDialog(false);
  };

  const updateAnalysisTrades = async (analysisWorkticketId) => {
    setLoadingUpdateAnalysis(true);
    const lensWorkticketAnalysis =
      analysisThread?.luna_lens_workticket_analysis?.find(
        (analysis) => analysis.id === analysisWorkticketId
      );
    const response = await updateAnalysis({
      lens_workticket_analysis: lensWorkticketAnalysis,
      analysis_id: analysisThread.id,
    });
    updateAnalysisTrade(
      response.data.analysis.luna_lens_workticket_analysis[0]
    );
    setLoadingUpdateAnalysis(false);
    setLoading(false);
  };

  const handleThumbUp = async (id, prompt, runId) => {
    setOpenLoading(true);
    const user = JSON.parse(localStorage.getItem("user"));
    await saveFeedback({
      type: "positive",
      reason: null,
      comment: null,
      run_id: runId,
      session_id: null,
      workticket_analysis_id: id,
      category: null,
      feedback_date: new Date().toISOString(),
      feedback_score: 1,
      feedback_reasons: ["Luna response met or exceeded my expectations."],
      prompt: prompt,
      user_id: user.id,
      user_email: user.email,
    });
    setWorkticketAnalysisFeedback(id, {
      type: "positive",
      reason: null,
      comment: null,
    });
    setOpenLoading(false);
  };

  const handleThumbDown = async (id, prompt, runId) => {
    setOpenFeedbackDialog(true);
    setIndexCategoryThumbDown({ id, category: null, prompt, runId });
  };

  const handleSaveAnalysis = async (thread) => {
    await saveAnalysis({
      unSaveId: savedAnalysis,
      saveId: thread.id,
    });
    setSavedLensAnalysis(savedAnalysis, "0");
    setSavedLensAnalysis(thread.id, "1");
    setSavedAnalysis(thread.id);
  };

  const handleMoreContext = async () => {
    setAnalyzing(true);
    setLoadingAddAnalysis(true);
    const user = JSON.parse(localStorage.getItem("user"));
    const lunaCurrentAnalysis =
      analysisThread?.luna_lens_workticket_analysis?.find(
        (analysis) => analysis.id === currentWorkticketAnalysis
      )?.luna_lens_analysis_categories;
    const lunaFormattedResponse =
      transformAnalysisResponse(lunaCurrentAnalysis);
    const data = {
      analysis_id: analysisThread.id,
      workticket_id: analysisThread.workticket_id,
      prompt:
        value2.length > 0
          ? value2
          : analysisThread?.luna_lens_workticket_analysis[0].prompt,
      prompt_categories: categoryContext,
      start: new Date().toISOString(),
      selected_images: selectedImages,
      reference_analysis:
        categoryContext.length > 0 ? lunaFormattedResponse : null,
      current_id: categoryContext.length > 0 ? currentWorkticketAnalysis : null,
      user_id: user.id,
      email: user.email,
    };
    const response = await addAnalysis(data);
    setAnalysisThread(response.data.analysis.analysis);
    setLoadingAddAnalysis(false);

    setValue2("");
    setCategoryContext([]);
    setAnalysisStarted(true);
  };

  if (!analysisThread)
    return (
      <Box className={classes.BodyDialogContainer}>
        <Box className={classes.newAnalysisContainerResponse}>
          <ZeroState
            title="We couldn't find your analysis"
            message="Your analysis could have been deleted."
          />
        </Box>
      </Box>
    );

  return (
    <Box className={classes.BodyDialogContainer}>
      <Box className={classes.newAnalysisContainerResponse}>
        {loadingAnalysis ? (
          <Box className={classes.commentsContainerSkeleton}>
            <Skeleton
              animation="wave"
              variant="rect"
              height={"90%"}
              style={{ marginTop: "24px" }}
            />
          </Box>
        ) : (
          <Box className={classes.commentsContainer}>
            {hasPermission(permissionSurface.WORKTICKET, permissions) ? (
              <LensCommentsSurface
                workticketId={analysisThread.workticket_id}
                workticketNumber={analysisThread.workticket_number}
              />
            ) : (
              <LensComments
                workticketId={analysisThread.workticket_id}
                workticketNumber={analysisThread.workticket_number}
              />
            )}
          </Box>
        )}
        {loadingAnalysis ? (
          <Skeleton
            animation="wave"
            variant="rect"
            height={190}
            style={{ marginBottom: "24px" }}
          />
        ) : (
          <AnalysisInformationCard
            analysisTitle={analysisThread?.name}
            location={analysisThread?.location}
            summary={analysisThread?.summary}
            description={analysisThread?.description}
          />
        )}
        {loadingAnalysis ? (
          <Skeleton
            animation="wave"
            variant="rect"
            height={"400px"}
            style={{ marginBottom: "24px" }}
          />
        ) : (
          <AnalysisCategoriesAccordionTab
            totalMedia={analysisThread?.total_media}
            analysisMedia={analysisThread?.luna_lens_analysis_media}
            workticketId={analysisThread.workticket_id}
            workticketNumber={analysisThread.workticket_number}
          />
        )}
        {!loadingAnalysis && (
          <>
            {analysisThread?.luna_lens_workticket_analysis?.map(
              (thread, index) =>
                parseInt(thread.loading) === 1 ? (
                  <React.Fragment key={index}>
                    {(thread.prompt ||
                      thread?.luna_lens_prompt_categories.length > 0) && (
                      <Box style={{ marginTop: "16px" }}>
                        <AnalysisPromptCard
                          prompt={thread.prompt}
                          categories={thread?.luna_lens_prompt_categories}
                        />
                      </Box>
                    )}
                    <Box>
                      <AnalysisLensProgress
                        startDateTime={thread.start}
                        totalFiles={analysisThread?.total_media}
                        status={parseInt(thread.status)}
                        uniqueFiles={analysisThread?.total_unique_media}
                        errorMessage={thread?.error}
                      />
                    </Box>
                  </React.Fragment>
                ) : (
                  <React.Fragment key={index}>
                    {(thread.prompt ||
                      thread?.luna_lens_prompt_categories.length > 0) && (
                      <Box style={{ marginTop: "16px" }}>
                        <AnalysisPromptCard
                          key={index}
                          prompt={thread.prompt}
                          categories={thread?.luna_lens_prompt_categories}
                        />
                      </Box>
                    )}
                    <AnalysisCard
                      key={index}
                      newAnalysisFlag
                      loading={analyzing}
                      setLoading={setLoading}
                      analysisThread={thread}
                      handleCategoryContext={addCategoryContext}
                      indexThread={index}
                      totalThreads={
                        analysisThread.luna_lens_workticket_analysis.length
                      }
                      onHandleSaveAnalysis={handleSaveAnalysis}
                      responseText={thread?.response_text}
                      onUpdateTrade={updateAnalysisTrades}
                      loadingUpdateAnalysis={loadingUpdateAnalysis}
                      onHandleThumbDown={() =>
                        handleThumbDown(thread.id, thread.prompt, thread.run_id)
                      }
                      onHandleThumbUp={() =>
                        handleThumbUp(thread.id, thread.prompt, thread.run_id)
                      }
                      fillUp={thread?.feedback?.type === "positive"}
                      fillDown={thread?.feedback?.type === "negative"}
                      workticketId={analysisThread.workticket_id}
                      workticketNumber={analysisThread.workticket_number}
                    />
                  </React.Fragment>
                )
            )}
            {!loadingAddAnalysis ? (
              <>
                {/* {!loading && ( */}
                <Box style={{ marginBottom: "32px" }}>
                  <FormInput
                    gridSizes={[{ size: "md", val: 12 }]}
                    name="prompt2"
                    placeholder="Add something here to deepen insights..."
                    styleOverride={
                      categoryContext.length === 0
                        ? { marginBottom: "32px" }
                        : {}
                    }
                    categories={categoryContext}
                    readonly={loading}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        setLoading(true);
                        handleMoreContext();
                      }
                    }}
                    endAdornment={
                      <IconButton
                        aria-label="close"
                        onClick={() => {
                          setLoading(true);
                          handleMoreContext();
                        }}
                        disabled={loading}
                        className={classes.sendButton}
                      >
                        <SendIcon className={classes.sendIcon} />
                      </IconButton>
                    }
                    value={value2}
                    onChange={(e) => setValue2(e.target.value)}
                  />
                </Box>
                {/* )} */}
              </>
            ) : (
              <Box style={{ marginBottom: "32px", marginTop: "24px" }}>
                <Skeleton
                  animation="wave"
                  variant="rect"
                  height={100}
                  style={{ marginBottom: "24px" }}
                />
              </Box>
            )}
          </>
        )}
      </Box>
      <DialogFeedbackLuna
        open={openFeedbackDialog}
        handleCancel={handleCloseFeedbackDialog}
        thread={indexCategoryThumbDown}
        type={"lensResponse"}
      />
      <DialogLoadingLuna open={openLoading} handleCancel={setOpenLoading} />
    </Box>
  );
};

export default NewAnalysisTab;
